import { t } from "i18next";
import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isLoginStateSelector } from "atoms/userInfoAtom";
import { loginTranslationKey } from "utils/CommonFunc";
import customAlert from "components/common/Alert/customAlert";

function PrivateRoute() {
  const location = useLocation();
  const isLoginState = useRecoilValue(isLoginStateSelector);

  useEffect(() => {
    if (isLoginState.nonLoginLauncher) {
      window.app.vfunRequestLogin();
    } else {
      if (!isLoginState.isLogIn) {
        localStorage.setItem("prevPath", location.pathname);
        if (location.search !== "") {
          localStorage.setItem("prevPath", location.pathname + location.search);
        }
        customAlert(t(loginTranslationKey));
      }
    }
  }, [isLoginState.isLogIn, isLoginState.isLauncher]);

  return isLoginState.isLogIn ? (
    <Outlet />
  ) : (
    <Navigate
      to={
        isLoginState.isLauncher ? "/" : "/membership/signin?service_code=vfun"
      }
    />
  );
}

export default PrivateRoute;
