import { isLoginStateSelector } from "atoms/userInfoAtom";
import customAlert from "components/common/Alert/customAlert";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { SIGN_IN_PAGE_CODE } from "routers/contant";
import { loginTranslationKey } from "utils/CommonFunc";

export default function useLoginCheck(isAlert: boolean) {
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const navigate = useNavigate();

  const ret = window.location.href.includes("/membership")
    ? ""
    : `?ret=${window.location.href}`;

  const alertMove = async () => {
    const result = await customAlert(t(loginTranslationKey));

    if (result) {
      navigate(SIGN_IN_PAGE_CODE + ret);
    }
  };

  const isNotLogin = () => {
    if (isLoginState.isLauncher) {
      window.app.vfunRequestLogin();
    } else if (isLoginState.isMobile) {
      customAlert(t(loginTranslationKey));
      return;
    } else {
      if (isAlert) {
        alertMove();
        return;
      }
      navigate(SIGN_IN_PAGE_CODE + ret);
    }
  };

  return isNotLogin;
}
