import "./Profile.scss";

import { useRecoilState, useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightToBracket,
  faUser,
  faCoins,
  faCreditCard,
  faHeadset,
  faAddressCard,
  faPowerOff
} from "@fortawesome/free-solid-svg-icons";
import { billingURL, LauncherLogin, Service_GL } from "utils/CommonFunc";
import { t } from "i18next";
import {
  isLoginStateSelector,
  userIdState,
  userNicknameState,
  userProfileKeyState
} from "atoms/userInfoAtom";
import { dropBoxToggleState } from "../recoil/recoil";
import Grade from "./Grade";
import Wallet from "./Wallet";
import { useLogout } from "hooks/useLogout";
import UserProfileImg from "components/common/UserProfileImg";
import { BILLING_CHARGE_URL } from "utils/config/Config";
import { CMservice_code } from "../utils/serviceCode";
import useLoginCheck from "hooks/useLoginCheck";

function Profile() {
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const userProfileKey = useRecoilValue(userProfileKeyState);
  const userNickname = useRecoilValue(userNicknameState);
  const userId = useRecoilValue(userIdState);

  const [dropBoxToggle, setDropBoxToggle] = useRecoilState(dropBoxToggleState);
  const style = { display: dropBoxToggle.profile ? "inline-block" : "none" };
  const onClickLogout = useLogout();
  const isNotLogin = useLoginCheck(false);

  return isLoginState.isLogIn ? (
    <div className="box drop" id="profile" style={style}>
      <div
        className="shadow"
        onClick={() =>
          setDropBoxToggle((prev) => ({ ...prev, profile: false }))
        }
      />
      <p className="title">{t("profile")}</p>
      <div className="row-flex">
        <Link
          to={`${process.env.REACT_APP_LOUNGE_DNS}/space/${userProfileKey}`}
        >
          <UserProfileImg />
          <p className="nickname ellipsis">
            {userNickname === "" ? userId : userNickname}
          </p>
        </Link>
        {Service_GL ? <Grade /> : null}
      </div>
      <Wallet />

      <ul className="info-wrap y-scroll">
        <li>
          <p className="sub-title">{t("cate_pay")}</p>
          <ul className="gray-box">
            <li>
              <Link
                to={window.location.href}
                onClick={() =>
                  billingURL(
                    BILLING_CHARGE_URL(CMservice_code, 2),
                    "_self",
                    isLoginState.isLauncher
                  )
                }
              >
                <FontAwesomeIcon icon={faCreditCard} />
                {t("charging_history")}
              </Link>
            </li>
            <li>
              <Link
                to={window.location.href}
                onClick={() =>
                  billingURL(
                    BILLING_CHARGE_URL(CMservice_code, 3),
                    "_self",
                    isLoginState.isLauncher
                  )
                }
              >
                <FontAwesomeIcon icon={faCoins} />
                {t("usage_history")}
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <p className="sub-title">{t("support")}</p>
          <ul className="gray-box">
            <li>
              <Link
                to={`${process.env.REACT_APP_PLATFORM_DNS}/customer/inquiry_list?service_code=vfun`}
              >
                <FontAwesomeIcon icon={faHeadset} />
                {t("my_inquiries")}
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <p className="sub-title">{t("account")}</p>
          <ul className="gray-box">
            <li>
              <Link
                to={`${process.env.REACT_APP_PLATFORM_DNS}/membership/sns_email_auth_confirm`}
              >
                <FontAwesomeIcon icon={faAddressCard} />
                {t("account_info")}
              </Link>
            </li>
            <li>
              <Link to={window.location.href} onClick={() => onClickLogout()}>
                <FontAwesomeIcon icon={faPowerOff} />
                {t("signout")}
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  ) : (
    <div className="box drop" id="profile" style={style}>
      <div
        className="shadow"
        onClick={() =>
          setDropBoxToggle((prev) => ({ ...prev, profile: false }))
        }
      />
      <p className="title">{t("signin")}</p>
      <ul className="sign-in">
        <li>
          <button className="purple btn" onClick={() => isNotLogin()}>
            <FontAwesomeIcon icon={faArrowRightToBracket} />
            {t("signin")}
          </button>
        </li>
        <li>
          <Link
            to={`${isLoginState.isLauncher ? "#" : "/membership/signup"}`}
            className="gray btn"
            onClick={() => LauncherLogin(isLoginState.isLauncher)}
          >
            <FontAwesomeIcon icon={faUser} />
            {t("signup")}
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Profile;
