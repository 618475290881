import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  cookieNoticeHiddenState,
  popUpNewsState,
  preRegisterPopUpState,
  snsQrPopUpState,
  snsSharePopUpState
} from "atoms/uiAtom";
import CombineMenu from "components/common/CombineMenu/CombineMenu";
import GuideLink from "components/common/GuideLink/GuideLink";
import CookieNotice from "components/common/CookieNotice/CookieNotice";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { isLoginStateSelector } from "atoms/userInfoAtom";
import PopNews from "components/LibraryComponent/PopUp/PopNews";
import EventPopUp from "components/LibraryComponent/PopUp/EventPopUp";
import SnsSharePopUp from "components/LibraryComponent/PopUp/SnsSharePopUp";
import SnsQRPopUp from "components/LibraryComponent/PopUp/SnsQRPopUp";
import Footer from "./Footer/Footer";

interface IContainer {
  children: React.ReactNode;
  background: string;
}

function Container(props: IContainer) {
  const { children, background } = props;
  const [isHidden, setIsHidden] = useRecoilState(cookieNoticeHiddenState);
  const popUpNews = useRecoilValue(popUpNewsState);
  const registerPopUp = useRecoilValue(preRegisterPopUpState);
  const snsSharePopUp = useRecoilValue(snsSharePopUpState);
  const snsQrPop = useRecoilValue(snsQrPopUpState);
  const params = useLocation();
  const [cookies, setCookies] = useCookies(["cookie_consent"]);
  const isLoginState = useRecoilValue(isLoginStateSelector);

  useEffect(() => {
    if (params.pathname === "/" || params.pathname === "/home") {
      if (
        cookies.cookie_consent === undefined ||
        cookies.cookie_consent === "N"
      ) {
        setIsHidden(true);
      }
    }
  }, []);

  const onClickCookies = () => {
    setIsHidden(false);
    setCookies("cookie_consent", "Y", { path: "/", domain: ".valofe.com" });
  };

  return (
    <div>
      {!isLoginState.isMobile && <CombineMenu />}
      <div
        id="wrapper"
        className="clearfix"
        onClick={onClickCookies}
        style={{ marginTop: isLoginState.isMobile ? "0" : "60px" }}
      >
        <Content id="content" background={background}>
          {/* page contents들이 여기 쌓임 */}
          {children}

          {/* 라이브러리 페이지에서 제거 */}
          {params.pathname.replace("/", "") === "library" ? null : (
            <GuideLink />
          )}
        </Content>
      </div>
      <Footer />

      {isLoginState.isLauncher || isLoginState.isMobile ? null : isHidden ? (
        <CookieNotice />
      ) : null}

      {/* 팝업들은 여기부터 쌓임 */}
      {popUpNews && <PopNews />}
      {registerPopUp && <EventPopUp />}
      {snsSharePopUp && <SnsSharePopUp />}
      {snsQrPop && <SnsQRPopUp />}
    </div>
  );
}

export default Container;

const Content = styled.div<{ background: string }>`
  width: 100%;
  padding-top: 60px;
  min-height: calc(100vh - 230px) !important; // 55 - 175
  z-index: 0;
  background: ${(props) => props.background || "#2f3136"} !important;

  @media all and (max-width: 1024px) {
    min-height: calc(100vh - 297px); // 50 - 247
  }
`;
