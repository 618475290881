import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { IFaq, getFAQApi } from "api/customer";
import { headersState, inputValueState } from "atoms/userInfoAtom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faUpload } from "@fortawesome/free-solid-svg-icons";
import "components/SupportComponent/inquiryList/Inquiry.scss";
import { useCookies } from "react-cookie";
import { langCodeState } from "atoms/contentsAtom";
import { allowedTypes } from "utils/CommonFunc";
import axios from "axios";
import { mobileInquiryApi } from "api/authApi";
import { useNavigate } from "react-router-dom";
import { wrapLoadingState } from "atoms/uiAtom";
import customAlert from "components/common/Alert/customAlert";
import useLoginCheck from "hooks/useLoginCheck";

function MobileInquiryForm() {
  const [category, setCategory] = useState("");
  const [inputValue, setInputValue] = useRecoilState(inputValueState);
  const imgsRef = useRef<HTMLInputElement | null>(null);
  const [imgName, setImgName] = useState<string[]>([]);
  const [imgFile, setImgFile] = useState<any | null>(null);
  const langCode = useRecoilValue(langCodeState);
  const [agree, setAgree] = useState(false);
  const navigate = useNavigate();
  const headers = useRecoilValue(headersState);
  const setIsLoading = useSetRecoilState(wrapLoadingState);
  const isNotLogin = useLoginCheck(true);

  const [cookies] = useCookies();
  const service_code = cookies["service_code"].toLowerCase();
  const user_app_id = cookies["user_app_id"];
  const device_info = cookies["device_info"];
  const game_ver = cookies["game_ver"];
  const os_ver = cookies["os_ver"];
  const platform = cookies["platform"];
  const game_server = cookies["game_server"];
  const user_nickname = cookies["user_nickname"];
  let postValue: any = {};

  useEffect(() => {
    if (
      service_code === "" ||
      service_code === undefined ||
      service_code === null
    ) {
      // navigate(-1);
    }
  }, []);

  const { isLoading: categoryListLoading, data: categoryList } = useQuery<IFaq>(
    ["faq", service_code, langCode],
    () => getFAQApi(service_code || "vfun", langCode)
  );

  const onSelectCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== "none") {
      setCategory(e.target.value);
    } else {
      setCategory("");
    }
  };

  const onAgreeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgree(e.target.checked);
  };

  const onImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let uploadFile: FileList | null = e.target.files;
    setImgFile(uploadFile);

    // 이미지 이름
    let fileArr = new Array();
    if (e.target.files !== null) {
      for (let i = 0; i < e.target.files.length; i++) {
        fileArr.push(e.target.files[i].name);
      }
      setImgName(fileArr);
    }
  };

  const onValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      category === "" ||
      inputValue.description === "" ||
      inputValue.subject === ""
    ) {
      customAlert("빈칸을 입력해주세요");
      return;
    }

    if (!agree) {
      customAlert(t("my_inquiry_desc7"));
      setAgree(false);
      return;
    }

    const formData = new FormData();
    if (imgFile !== null && imgFile !== undefined && imgFile.length !== 0) {
      for (let i = 0; i < imgFile.length; i++) {
        // 용량
        if (imgFile[i].size > 1048576) {
          customAlert(`${t("uploadable_file_size_is_1mb")} ${imgFile[i].name}`);
          return false;
        }

        // 타입
        if (!allowedTypes.includes(imgFile[i].type)) {
          customAlert(
            t(
              "The file formats that can be uploaded are gif, jpg, png and bmp."
            )
          );
          return false;
        }
      }
      // 장 수
      if (imgFile.length > 2) {
        customAlert(t("up_to_2_attachments_can_be_uploaded"));
        return false;
      }
      Object.values(imgFile).forEach((file: any) => {
        formData.append("images[]", file);
      });
    }
    formData.append("category", category);
    formData.append("email", inputValue.email);
    formData.append("user_id", inputValue.id);
    formData.append("subject", inputValue.subject);
    formData.append("description", inputValue.description);
    formData.append("user_lang", langCode);

    // formData.append("service_code", service_code);
    formData.append("user_app_id", user_app_id);
    formData.append("device_info", device_info);
    formData.append("game_ver", game_ver);
    formData.append("os_ver", os_ver);
    formData.append("platform", platform);
    formData.append("game_server", game_server);
    formData.append("user_nickname", user_nickname);

    postValue = formData;

    if (agree) {
      setIsLoading(true);
      try {
        let res = await axios.post(mobileInquiryApi(service_code), postValue, {
          headers: headers
        });
        if (res.data.result === 1) {
          setIsLoading(false);
          customAlert(t("my_inquiry_desc9"));
          navigate(-1);
        } else if (res.data.result === -99) {
          setIsLoading(false);
          isNotLogin();
        } else {
          setIsLoading(false);
          customAlert(t("my_inquiry_desc13"));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onClickX = (img: string) => {
    // 이미지 이름
    const newImgNameArr = imgName.filter((item) => item !== img);
    setImgName(newImgNameArr);

    // 이미지 파일
    if (imgFile !== null && imgFile !== undefined && imgFile.length !== 0) {
      const newImgFileArr = Object.values(imgFile).filter(
        (file: any) => file.name !== img
      );

      setImgFile(newImgFileArr);
    }
  };

  return (
    <form
      method="post"
      encType="multipart/form-data"
      id="formInquiry"
      onSubmit={onSubmit}
    >
      <input type="hidden" name="attach_file_count" value="" />
      <section className="sec2 inquiry-write">
        <table className="question">
          <colgroup>
            <col width="22%" />
            <col width="*" />
          </colgroup>
          <tbody>
            {/* 상단 title */}
            <tr className="game-tit-tr">
              <th>
                <h4>Game</h4>
              </th>
              <td>
                <div className="game-tit">{t(service_code)}</div>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>&nbsp;</th>
            </tr>
            {/* 카테고리 */}
            <tr>
              <th>
                <h4>{t("form_category")}</h4>
              </th>
              <td>
                <select
                  name="category"
                  id="category"
                  onChange={onSelectCategory}
                >
                  <option value="none">
                    {t("please_select_from_the_following_categories")}
                  </option>
                  {!categoryListLoading
                    ? categoryList?.result === 1
                      ? categoryList.data.map((i) => (
                          <option value={i.idx} key={i.idx}>
                            {i.category_name}
                          </option>
                        ))
                      : null
                    : null}
                </select>
              </td>
            </tr>
            {/* 이메일 */}
            <tr>
              <th>
                <h4>{t("email")}</h4>
              </th>
              <td>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder={t("email")}
                  value={inputValue.email || ""}
                  onChange={onValueChange}
                />
              </td>
            </tr>
            {/* 아이디 */}
            <tr>
              <th>
                <h4>{t("id")}</h4>
              </th>
              <td>
                <input
                  type="text"
                  name="id"
                  id="id"
                  placeholder={t("id")}
                  value={inputValue.id || ""}
                  onChange={onValueChange}
                />
              </td>
            </tr>
            {/* 제목 */}
            <tr>
              <th>
                <h4>{t("subject")}</h4>
              </th>
              <td>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder={t("please_write_the_subject_of_your_inquiry")}
                  value={inputValue.subject}
                  onChange={onValueChange}
                />
              </td>
            </tr>
            {/* 내용 */}
            <tr>
              <th>
                <h4>{t("description")}</h4>
              </th>
              <td>
                <textarea
                  name="description"
                  id="description"
                  placeholder={t(
                    "please_write_the_descriptions_of_your_inquiry"
                  )}
                  value={inputValue.description}
                  onChange={onValueChange}
                ></textarea>
              </td>
            </tr>
            {/* 파일 */}
            <tr className="file">
              <th rowSpan={2}>
                <h4>{t("attach_file")}</h4>
              </th>
              <td>
                {imgName.map((i: string, idx: number) => (
                  <div key={idx}>
                    {i}{" "}
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      className="icon"
                      onClick={() => onClickX(i)}
                    />
                  </div>
                ))}
                <div className="attatch-wrap">
                  <label htmlFor="file">
                    <FontAwesomeIcon icon={faUpload} /> {t("file")}
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      id="file"
                      ref={imgsRef}
                      onChange={onImgChange}
                    />
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="sec3 inquiry-send">
        <div className="row-w">
          <h4 className="font-100">
            We collect information pertaining to
            <br />
            'email, ID or Nickname, IP and the Contents of the text' for the
            purpose of customer consultation,
            <br />
            and keep the collected personal information for 3 Years according to
            the relevant law.
            <br />
            You may refuse to collect personal information under the Personal
            Information Protection Act and if refused, the inquiry will not be
            submitted.{" "}
          </h4>
          <fieldset>
            <legend className="hidden">send</legend>
            <div className="wrap-agree">
              <label htmlFor="chk-agree" className="">
                <input
                  type="checkbox"
                  name="chk-agree"
                  id="chk-agree"
                  onChange={onAgreeCheck}
                />
                &nbsp;I agree to the Collection/Use of personal information.{" "}
              </label>
            </div>
            <div className="wrap-btn">
              <label htmlFor="btn-send" className="hidden">
                send
              </label>
              <button className="btn" id="btn-send">
                send
              </button>
            </div>
          </fieldset>
        </div>
      </section>
    </form>
  );
}

export default MobileInquiryForm;
