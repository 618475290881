import { t } from "i18next";
import React from "react";
import styled from "styled-components";
import CombineMenu from "../CombineMenu/CombineMenu";
import ControlLoading from "components/loading/ControlLoading";
import { useRecoilValue } from "recoil";
import { wrapLoadingState } from "atoms/uiAtom";
import { isMobileAppState } from "atoms/userInfoAtom";
import Fall from "../../../pages/membership/theme/Fall";
import Footer from "../Footer/Footer";

interface IProps {
  children: React.ReactNode;
  title: string;
  mb: string;
  boxWidth?: string;
}

function MembershipWrap(props: IProps) {
  const isLoading = useRecoilValue(wrapLoadingState);
  const isMobileApp = useRecoilValue(isMobileAppState);

  return (
    <div>
      {!isMobileApp && <CombineMenu />}
      <div
        id="wrapper"
        className="clearfix"
        style={{ marginTop: isMobileApp ? "0" : "60px" }}
      >
        <Content id="content" className="bg-fall clearfix">
          {" "}
          {/*bg-space*/}
          {/* page contents들이 여기 쌓임 */}
          {/* <BgMotion className="bg-motion" /> */}
          {/*<canvas id="gsp_canvas" width="1920" height="997" />*/}
          {/* 여름 태마 */}
          {/* <Summer /> */}
          {/* 가을 테마 */}
          <Fall />
          <section className="section-login">
            <div className={`box ${props.boxWidth} clearfix`}>
              <div className="box-cont left">
                <h1 className={props.mb}>{t(props.title)}</h1>
                {props.children}
              </div>
              <div className="box-cont promote right">
                <span className="area-logo" title="VFUN Logo"></span>
                <span className="area-greeting" title="Welcome to VFUN"></span>
                <span className="area-character">
                  <span className="area-gamepad" title="Gamepad Image"></span>
                  <i className="twinkle1"></i>
                  <i className="twinkle2"></i>
                  <i className="twinkle3"></i>
                  <i className="twinkle4"></i>
                  <span
                    className="area-funny"
                    title="FUNNY - VFUN main Character"
                  >
                    <i className="funny-eye double-blink"></i>
                  </span>
                </span>
              </div>
            </div>
          </section>
        </Content>
      </div>
      <Footer />

      {isLoading && <ControlLoading />}
    </div>
  );
}

export default MembershipWrap;

const Content = styled.div`
  min-height: calc(100vh - 110px) !important;
  height: auto;
  display: flex;
  align-items: center;

  .right {
    height: 100%;
    position: absolute;
    display: inline-block;
    background: rgb(40, 42, 50);
  }

  .authfail {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 14rem;
    padding: 3rem 0;
    text-align: center;
  }

  @media all and (max-width: 1024px) {
    min-height: calc(100vh - 297px); // 50 - 247
  }
`;
