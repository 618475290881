import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isLoginStateSelector } from "atoms/userInfoAtom";
import { VFUN_IMAGE_DNS } from "utils/config/Config";
import "./GuideLink.scoped.scss";
import { t } from "i18next";
import useLoginCheck from "hooks/useLoginCheck";

function GuideLink() {
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const navigate = useNavigate();
  const isNotLogin = useLoginCheck(true);
  const commonUrl = "web/vfun/images/common";
  const [supportImg, setSupportImg] = useState(
    `${VFUN_IMAGE_DNS}/${commonUrl}/icon-support-on.png`
  );
  const [downloadImg, setDownloadtImg] = useState(
    `${VFUN_IMAGE_DNS}/${commonUrl}/icon-download-on.png`
  );

  const onClickQuide = () => {
    if (isLoginState.isLogIn) {
      navigate("/customer/inquiry_list");
    } else {
      isNotLogin();
    }
  };

  return (
    <section className="guide-link">
      <div className="row-w">
        <h1>[ GUIDE LINK ]</h1>
        <ul className="clearfix">
          {/* <li
            onMouseEnter={() =>
              setEventImg(`${VFUN_IMAGE_DNS}/${commonUrl}/icon-event.png`)
            }
            onMouseLeave={() =>
              setEventImg(`${VFUN_IMAGE_DNS}/${commonUrl}/icon-event-on.png`)
            }
          >
            <Link to="#">
              <img className="guide-icon" src={eventImg} alt="event" />
              <span className="guide-txt">Event</span>
            </Link>
          </li> */}
          <li
            onMouseEnter={() =>
              setSupportImg(`${VFUN_IMAGE_DNS}/${commonUrl}/icon-support.png`)
            }
            onMouseLeave={() =>
              setSupportImg(
                `${VFUN_IMAGE_DNS}/${commonUrl}/icon-support-on.png`
              )
            }
            onClick={onClickQuide}
          >
            <Link to={window.location.href}>
              <img className="guide-icon" src={supportImg} alt="support" />
              <span className="guide-txt">1:1 INQUIRY</span>
            </Link>
          </li>
          {isLoginState.isLauncher ? null : (
            <li
              onMouseEnter={() =>
                setDownloadtImg(
                  `${VFUN_IMAGE_DNS}/${commonUrl}/icon-download.png`
                )
              }
              onMouseLeave={() =>
                setDownloadtImg(
                  `${VFUN_IMAGE_DNS}/${commonUrl}/icon-download-on.png`
                )
              }
            >
              <Link to="/game/download">
                <img
                  className="guide-icon"
                  src={downloadImg}
                  alt="game download"
                />
                <span className="guide-txt">LAUNCHER DOWNLOAD</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </section>
  );
}

export default GuideLink;
